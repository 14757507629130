import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Sidebar(props) {
    const {archives, description, social, title} = props;

    return (
        <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{p: 2, bgcolor: 'grey.200'}}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography>{description}</Typography>
                <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                    Archiwum
                </Typography>
                {archives.map((archive) => (
                    <Link display="block" variant="body1" href={archive.url} key={archive.title}>
                        {archive.title}
                    </Link>
                ))}
            </Paper>
        </Grid>
    );
}

Sidebar.propTypes = {
    archives: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    description: PropTypes.string.isRequired,
    social: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.elementType.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string.isRequired,
};

export default Sidebar;
