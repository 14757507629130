import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Helmet} from 'react-helmet';

export default function AboutUs() {
    return (
        <Container>
            <Helmet>
                <title>O nas | Ewieczyste.pl</title>
                <meta name="description"
                      content="Dowiedz się więcej o naszej firmie i misji. Poznaj historię i wartości Ewieczyste.pl."/>
            </Helmet>
            <Box sx={{my: 4}}>
                <Typography variant="h2" component="h1" gutterBottom>
                    O nas
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Nasza misja
                </Typography>
                <Typography variant="body1" gutterBottom>
                    W naszym serwisie, dążymy do zapewnienia przejrzystości, dostępności i efektywności w zarządzaniu
                    danymi katastralnymi. Naszą misją jest ułatwienie dostępu do ksiąg wieczystych oraz innych istotnych
                    informacji dotyczących nieruchomości, w sposób bezpieczny i zrozumiały dla każdego. Dzięki
                    innowacyjnym rozwiązaniom technologicznym i wysokim standardom obsługi, chcemy wspierać zarówno
                    osoby prywatne, jak i profesjonalistów w obszarze nieruchomości, zapewniając im szybki i łatwy
                    dostęp do niezbędnych danych. Naszym celem jest również promowanie przejrzystości i uczciwości na
                    rynku nieruchomości, eliminując bariery informacyjne i wspierając sprawiedliwe transakcje.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Historia
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Ewieczyste.pl zostało założone w 2021 roku z myślą o potrzebach nowoczesnych użytkowników.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Nasze wartości
                </Typography>

                <Typography fontWeight="bold">
                    Przejrzystość
                </Typography>

                Wierzymy, że dostęp do informacji powinien być łatwy i bezpieczny. Naszym priorytetem jest
                dostarczanie klarownych, dokładnych i aktualnych danych, które umożliwiają naszym użytkownikom
                podejmowanie świadomych decyzji.


                <Typography fontWeight="bold">
                    Dostępność
                </Typography>
                Dążymy do tego, aby nasze usługi były dostępne dla każdego, bez względu na lokalizację czy status.
                Oferujemy intuicyjny i wygodny dostęp do ksiąg wieczystych, aby każdy użytkownik mógł korzystać z
                naszej platformy bez przeszkód.


                <Typography fontWeight="bold">
                    Bezpieczeństwo
                </Typography>
                Ochrona danych jest dla nas priorytetem. Zapewniamy najwyższe standardy bezpieczeństwa, aby chronić
                prywatność naszych użytkowników i integralność informacji. Nasze systemy są zabezpieczone zgodnie z
                najnowszymi normami i regulacjami.


                <Typography fontWeight="bold">
                    Innowacyjność
                </Typography>
                Jesteśmy zobowiązani do ciągłego doskonalenia naszych usług i rozwiązań technologicznych.
                Inwestujemy w nowoczesne technologie, aby dostarczać naszym użytkownikom najbardziej efektywne i
                wygodne narzędzia do zarządzania danymi nieruchomości.


                <Typography fontWeight="bold">
                    Uczciwość
                </Typography>
                Nasze działania opierają się na zasadach etyki i transparentności. Dążymy do utrzymania najwyższych
                standardów uczciwości w każdym aspekcie naszej działalności, zapewniając rzetelne i wiarygodne
                informacje dla naszych użytkowników.


                <Typography fontWeight="bold">
                    Wsparcie i Profesjonalizm
                </Typography>
                Nasz zespół jest gotowy do pomocy i wsparcia na każdym etapie korzystania z naszych usług. Zależy
                nam na zadowoleniu naszych użytkowników, dlatego zapewniamy profesjonalne wsparcie i chętnie
                odpowiadamy na wszelkie pytania oraz uwagi.

                <Typography variant="h6" component="h2" gutterBottom>
                    Nasze Opłaty
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Aby utrzymać wysoką jakość naszych usług i zapewnić nieprzerwaną dostępność danych, pobieramy niską
                    opłatę za korzystanie z naszych usług. Koszty te są głównie przeznaczone na pokrycie wydatków
                    związanych z utrzymaniem i rozwojem serwerów oraz infrastruktury technologicznej. Dzięki temu możemy
                    kontynuować dostarczanie wiarygodnych i aktualnych informacji, jednocześnie dbając o bezpieczeństwo
                    i efektywność naszej platformy.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Dlaczego Warto Nas Wybrać?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Jesteśmy liderem w dziedzinie udostępniania danych katastralnych i ksiąg wieczystych, łącząc
                    innowacyjność z solidnością. Nasza misja i wartości odzwierciedlają nasze zaangażowanie w tworzenie
                    platformy, która jest zarówno użyteczna, jak i bezpieczna. Dzięki naszym przystępnym opłatom, każdy
                    ma możliwość uzyskania rzetelnych informacji o nieruchomościach, co przyczynia się do sprawiedliwego
                    i przejrzystego obrotu nieruchomościami.
                </Typography>
            </Box>
        </Container>
    );
}
