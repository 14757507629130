import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useState} from "react";

function createData(
    identyfikator,
    teryt,
    obreb,
    numer_dzialki,
    wojewodztwo,
    powiat,
    gmina,
    powierzchnia,
    numer_ksiegi,
    kw_id
) {
    return {identyfikator, teryt, obreb, numer_dzialki, wojewodztwo, powiat, gmina, powierzchnia, numer_ksiegi, kw_id};
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function ResultCard(params) {
    let result = params.result;
    let isMobile = params.isMobile;

    const rows = [
        createData(result.parcel_evidence_id, result.teryt, result.obreb, result.parcel_number,
            result.wojewodztwo, result.powiat, result.gmina, result.evidence_area, result.kw_full_id, result.kw_id),
    ];

    if (isMobile) {
        return (
            <Paper style={{height: 510, marginBottom: 10}}>
                {rows.map((row) => (
                    <TableContainer key={row.identyfikator}>
                        <Table sx={{}} aria-label="simple table">
                            <TableBody>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}} component="th" scope="row">
                                        Identyfikator: {row.identyfikator}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Teryt: {row.teryt}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Numer działki: {row.numer_dzialki}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Województwo: {row.wojewodztwo}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Powiat: {row.powiat}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Gmina: {row.gmina}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Powierzchnia: {row.powierzchnia}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell style={{fontWeight: 600, fontFamily: 'lato'}}>
                                        Numer księgi: {row.numer_ksiegi}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ))}
                <div style={{textAlign: 'center', 'padding-left': 20, 'padding-right': 20, 'margin-top': 10}}>
                    <Button onClick={params.displayKw}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                fontWeight: 500,
                                fontFamily: 'lato',
                                'float': 'left'
                            }}
                            value={result.kw_id}
                            variant="contained" size="large" endIcon={<VisibilityIcon/>}>
                        Zobacz księgę {result.kw_full_id}
                    </Button>
                </div>
            </Paper>
        )
    } else return (
        <Paper style={{width: '100%', height: 200, 'margin-bottom': 10}}>
            <div>
                <TableContainer>
                    <Table sx={{width: isMobile ? '100%' : 1000}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Identyfikator</TableCell>
                                <TableCell align="left">Teryt</TableCell>
                                <TableCell align="left">Numer działki</TableCell>
                                <TableCell align="left">Województwo</TableCell>
                                <TableCell align="left">Powiat</TableCell>
                                <TableCell align="left">Gmina</TableCell>
                                <TableCell align="left">Powierzchnia</TableCell>
                                <TableCell align="left">Numer księgi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.identyfikator}
                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}} component="th"
                                               scope="row">
                                        {row.identyfikator}
                                    </TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.teryt}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.numer_dzialki}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.wojewodztwo}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.powiat}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.gmina}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.powierzchnia}</TableCell>
                                    <TableCell style={{fontWeight: 600, 'font-family': 'lato'}}
                                               align="left">{row.numer_ksiegi}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{'margin-left': 325, 'margin-top': 15}}>
                <Button onClick={params.displayKw}
                        style={{width: 350, height: 50, fontSize: 16, fontWeight: 500, 'font-family': 'lato'}}
                        value={result.kw_id}
                        variant="contained" size="large" endIcon={<VisibilityIcon/>}>
                    Zobacz księgę {result.kw_full_id}
                </Button>
            </div>
        </Paper>
    )
}

function ResultCards(params) {
    let kwResults = params.kwResults;
    let isMobile = params.isMobile;

    return (
        <div style={{'padding-left': isMobile ? 20 : 0, 'padding-right': isMobile ? 20 : 0, width: '100%'}}>
            {kwResults && kwResults.map(function (result, i) {
                return <ResultCard result={result} displayKw={params.displayKw} isMobile={isMobile}/>
            })}
        </div>
    )

}

function Results(params) {

    let kwResults = params.kwResults && params.kwResults;
    let isMobile = params.isMobile;
    return (
        <>
            <div style={{width: isMobile ? '100%' : 1000}}>
                <div>
                    <span style={{
                        'font-family': 'lato', 'font-size': 32, color: '#3D3C09', fontWeight: 500,
                        'padding-left': isMobile ? 20 : 360
                    }}>
                        Wyniki wyszukiwania
                    </span>
                </div>
                <div style={{'padding-top': 35}}>
                    <ResultCards kwResults={kwResults} displayKw={params.displayKw} isMobile={isMobile}/>
                </div>

            </div>
        </>
    )
}

export default Results;
