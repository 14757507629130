import React, {useEffect, useState} from 'react';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


function LandingDescription(params) {
    const {height, width} = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(width < 1000);
    }, [width < 1000]);

    return (
        <div>
            <div style={{
                backgroundColor: 'white',
                width: '100%',
                'padding-left': isMobile ? 20 : (width - 1000) / 2,
                'padding-right': isMobile ? 20 : (width - 1000) / 2,
                'padding-bottom': 20,
                'font-family': 'lato'
            }}>
                <h1 style={{'padding-left': isMobile ? 0 : 350}}>Znajdź księgę wieczystą</h1>
                Nasza wyszukiwarka ksiąg wieczystych pozwala szybko i łatwo uzyskać dostęp do aktualnych danych
                dotyczących nieruchomości. Dzięki niej możesz sprawdzić właściciela, obciążenia i inne istotne
                informacje o dowolnej nieruchomości w Polsce. Skorzystaj z naszej usługi już dziś!
            </div>
            <div style={{
                backgroundColor: '#f6f6f6',
                width: '100%',
                'padding-left': isMobile ? 20 : (width - 1000) / 2,
                'padding-right': isMobile ? 20 : (width - 1000) / 2,
                'padding-top': 10,
                'padding-bottom': 10,
                'font-family': 'lato'
            }}>
                <h1 style={{'padding-left': isMobile ? 0 : 320}}>Co zawiera księga wieczysta?</h1>
                Księga wieczysta jest dokumentem, który zawiera informacje dotyczące nieruchomości (np. działki,
                budynku) i jest prowadzona przez sąd rejonowy właściwy dla danej nieruchomości.

                Główne elementy zawarte w księdze wieczystej to:
                <ul>
                    <li>Dane o nieruchomości: adres, numer działki, opis nieruchomości (np. rodzaj budynku,
                        powierzchnia, przeznaczenie)
                    </li>
                    <li>Informacje o właścicielu nieruchomości: imię i nazwisko, adres zamieszkania</li>
                    <li>Obciążenia nieruchomości: np. hipoteki, zastawy, służebności, ograniczenia własności</li>
                    <li>Wpisy dotyczące zmian w stanie prawnym nieruchomości: np. zmiany właściciela, obciążenia,
                        przeznaczenie nieruchomości
                    </li>
                </ul>
            </div>
            <div style={{
                backgroundColor: 'white',
                width: '100%',
                'padding-left': isMobile ? 20 : (width - 1000) / 2,
                'padding-right': isMobile ? 20 : (width - 1000) / 2,
                'padding-bottom': 20,
                'font-family': 'lato'
            }}>
                <h1 style={{'padding-left': isMobile ? 0 : 280}}>Do czego przydają się księgi wieczyste?</h1>
                Głównym celem ksiąg wieczystych jest udostępnienie aktualnych danych dotyczących stanu prawnego
                nieruchomości oraz umożliwienie dokonywania zmian w tym stanie.

                Do głównych zastosowań ksiąg wieczystych należą:
                <ul>
                    <li>Ustalenie właściciela nieruchomości: księga wieczysta zawiera imię i nazwisko właściciela oraz
                        adres zamieszkania
                    </li>
                    <li>Sprawdzenie obciążeń nieruchomości: księga wieczysta zawiera informacje o ewentualnych
                        hipotekach, zastawach, służebnościach i innych ograniczeniach własności
                    </li>
                    <li>Zawieranie umów dotyczących nieruchomości: przy zakupie, sprzedaży lub wynajmie nieruchomości
                        konieczne jest sprawdzenie stanu prawnego nieruchomości w księdze wieczystej
                    </li>
                    <li>Dokonywanie zmian w stanie prawnym nieruchomości: np. przeniesienie własności, dodanie lub
                        usunięcie obciążeń, zmiana przeznaczenia nieruchomości
                    </li>
                    <li>Udzielanie poświadczeń o stanie prawnym nieruchomości: np. wymagane przy uzyskiwaniu kredytu
                        hipotecznego, rejestracji samochodu itp.
                    </li>
                </ul>
            </div>
            <div style={{
                backgroundColor: '#f6f6f6',
                width: '100%',
                'padding-left': isMobile ? 20 : (width - 1000) / 2,
                'padding-right': isMobile ? 20 : (width - 1000) / 2,
                'padding-top': 10,
                'padding-bottom': 10,
                'font-family': 'lato'
            }}>
                <h1 style={{'padding-left': isMobile ? 0 : 280}}>Jak zbudowana jest księga wieczysta?</h1>

                <ul>
                    <li><b>Dział I-O - Oznaczenie nieruchomości - </b> zawiera podstawowe informacje o nieruchomości,
                        takie jak jej lokalizacja, numer działki i obrębu ewidencyjnego oraz powierzchnia.
                    </li>
                    <li><b>Dział I-Sp - Spis praw związanych z własnością -</b> zawiera informacje o prawach związanych
                        z własnością nieruchomości.
                    </li>
                    <li><b>Dział II - Własność -</b> zawiera informacje o prawnym właścicielu nieruchomości.</li>
                    <li><b>Dział III - Prawa i roszczenia -</b> zawiera informacje o prawach i roszczeniach dotyczących
                        prawa rzeczowego.
                    </li>
                    <li><b>Dział IV - Hipoteka -</b> zawiera informacje o hipotekach na nieruchomości.</li>
                </ul>
            </div>
            {/*<div style={{ backgroundColor: 'white', width: '100%', 'padding-left': isMobile ? 20 : (width-1000)/2,*/}
            {/*    'padding-right': isMobile ? 20 : (width-1000)/2,*/}
            {/*     'padding-bottom': 20, 'font-family': 'lato'}}>*/}
            {/*    <h1 style={{'padding-left': isMobile ? 0 : 280}}>Ile kosztuje uzyskanie numeru księgi wieczystej?</h1>*/}
            {/*    Za ustalenie numeru księgi wieczystej na podstawie adresu nieruchomości lub numeru działki należy zapłacić 20 zł.*/}
            {/*    Pamiętajmy jednak, że ustalenie numeru księgi wieczystej nie oznacza automatycznego otrzymania odpisu jej treści. Ten możemy uzyskać wyłącznie w sądzie rejonowym lub na stronie Ministerstwa Sprawiedliwości.*/}
            {/*</div>*/}

        </div>
    )
}

export default LandingDescription;
