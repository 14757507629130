import React, {useEffect, useState} from 'react';


import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/Layout";
import Landing from "./pages/Landing";
import NoPage from "./pages/NoPage";
import KW from "./pages/Kw";
import Blog from "./blog/Blog"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"
import AboutUs from "./pages/AboutUs";


function App() {
    useEffect(() => {
        const currentDomain = window.location.hostname;
        const desiredDomain = 'www.ewieczyste.io';

        if (currentDomain === 'ewieczyste.io') {
            window.location.replace(`https://${desiredDomain}${window.location.pathname}${window.location.search}`);
        }
    }, []);


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Landing/>}/>
                    <Route path="kw/:sessionId" element={<KW/>}/>
                    <Route path="blog" element={<Blog/>}/>
                    <Route path="blog/:postName" element={<Blog/>}/>
                    <Route path="polityka-prywatnosci" element={<Privacy/>}/>
                    <Route path="regulamin" element={<Terms/>}/>
                    <Route path="o-nas" element={<AboutUs/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>

    );
}

export default App;
