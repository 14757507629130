import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import {Helmet} from 'react-helmet';

export default function TermsOfService() {
    return (
        <Container>
            <Helmet>
                <title>Regulamin | Ewieczyste.pl</title>
                <meta name="description"
                      content="Zapoznaj się z regulaminem świadczonych przez Nas usług. Dowiedz się więcej o zasadach korzystania z serwisu Ewieczyste.pl."/>
            </Helmet>
            <Box sx={{my: 4}}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Regulamin Serwisu Ewieczyste.pl
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    1. Wprowadzenie
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Dziękujemy za wybór Ewieczyste.pl. Niniejszy regulamin określa zasady korzystania z naszej
                    wyszukiwarki ksiąg wieczystych. Każdy, kto korzysta z serwisu, akceptuje niniejsze warunki.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    2. Usługi oferowane przez serwis
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nasza platforma umożliwia użytkownikom odkrywanie numerów ksiąg wieczystych za opłatą w wysokości
                    4.99zł.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    3. Reklamacje i postępowanie reklamacyjne
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Jeśli użytkownik napotka jakiekolwiek problemy związane z korzystaniem z naszej platformy, ma prawo
                    złożyć reklamację. Wszystkie reklamacje można zgłaszać poprzez kontakt na adres e-mailowy: <a
                    href="mailto:pomoc@ewieczyste.pl">pomoc@ewieczyste.pl</a>.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    4. Polityka prywatności
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Dbamy o prywatność naszych użytkowników. W związku z tym nie używamy plików cookies na naszej
                    stronie. Do przetwarzania płatności korzystamy z serwisu Stripe, który zapewnia bezpieczeństwo
                    transakcji.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    5. Odpowiedzialność użytkownika
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wszystkie próby nieautoryzowanego dostępu, modyfikacji lub uszkodzenia danych serwisu są surowo
                    zabronione. Naruszenie tych zasad może skutkować odpowiedzialnością prawną.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    6. Aktualizacje regulaminu
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Zastrzegamy sobie prawo do wprowadzania zmian w niniejszym regulaminie. Informujemy o nich
                    użytkowników na bieżąco. Kontynuując korzystanie z serwisu po wprowadzeniu zmian, użytkownik wyraża
                    zgodę na nowe postanowienia.
                </Typography>
                <Divider/>

            </Box>
        </Container>
    );
}
